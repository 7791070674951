import React from 'react'
import { TechnicalArticle } from '../models/technicalArticle'
import { ExternalLink } from 'react-external-link'
import Section from './section'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'

export const TechnicalArticleList: React.FC<{
  articles: TechnicalArticle[]
}> = ({ articles }) => (
  <div className="row">
    {articles.map((article, index) => (
      <TechnicalArticleComponent key={index} article={article} />
    ))}
  </div>
)

export const TechnicalArticleComponent: React.FC<{
  article: TechnicalArticle
}> = ({ article }) => (
  <div className="col-sm-6 col-lg-4 mt-3">
    <div
      className="feature-box fbox-center fbox-bg mt-1"
      style={{ height: '100%' }}
    >
      <div className="fbox-content mt-4">
        <h3>
          <ExternalLink href={article.link}>{article.title}</ExternalLink>
          <span className="subtitle">{article.date}</span>
        </h3>
        <p>{article.description}</p>
      </div>
    </div>
  </div>
)

export const TechnicalArticlesSection: React.FC<{
  articles: TechnicalArticle[]
  count: number
}> = ({ articles, count }) => (
  <Section transparent={true}>
    <div className="heading-block center">
      <h3>最新技术文章</h3>
      <span>发布在 IBM developerWorks 上的技术文章</span>
    </div>
    <TechnicalArticleList articles={articles} />
    <div className="mt-3 text-center">
      <Link to="/published-articles/">
        <button className="button button-xlarge button-dark button-rounded text-right">
          查看全部技术文章（{count}）
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </button>
      </Link>
    </div>
  </Section>
)
