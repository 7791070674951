import { TechnicalArticle } from '../models/technicalArticle'

const technicalArticles: TechnicalArticle[] = [
  {
    title: '使用 Quarkus 开发 Kubernetes 原生 Java 应用',
    date: '2019-12-09',
    link:
      'https://www.ibm.com/developerworks/cn/java/j-use-quarkus-to-develop-kubernetes-native-java-application/index.html',
    description:
      'Quarkus 基于 OpenJDK HotSpot 虚拟机和 GraalVM，用于在 Kubernetes 平台上创建可以迅速启动且耗费资源少的 Java 应用。Quarkus 集成了很多 Java 开发中常用的标准和框架，可以缩短从开发到部署的时间。本文对 Quarkus 进行介绍，帮助您了解和开始使用 Quarkus。',
  },
  {
    title: '使用 GraalVM 开发多语言应用',
    date: '2019-11-12',
    link:
      'https://www.ibm.com/developerworks/cn/java/j-use-graalvm-to-run-polyglot-apps/index.html',
    description:
      'GraalVM 是一个统一的虚拟机，支持运行不同语言的应用，包括 JVM 语言、JavaScript、Python、Ruby、R 和 C/C++等。GraalVM 可以创建基于 JVM 的原生应用镜像来降低启动时间和内存消耗。本文对 GraalVM 进行详细介绍，包括多语言开发和 JVM 原生应用镜像支持。',
  },
  {
    title: '使用 RSocket 进行反应式数据传输',
    date: '2019-10-14',
    link:
      'https://www.ibm.com/developerworks/cn/java/j-using-rsocket-for-reactive-data-transfer/index.html',
    description:
      'RSocket 作为提供了反应式编程语义的二进制应用协议，可以用在基于二进制字节流的传输协议上，包括 TCP、WebSockets 和 Aeron。RSocket 协议在同一个连接上进行异步消息传递，支持四种不同的交互模式。本文对 RSocket 协议及其所支持的四种交互模式进行介绍，并介绍 RSocket 与 Spring 框架和 WebSocket 的集成。',
  },
  {
    title: '使用 Micrometer 记录 Java 应用性能指标',
    date: '2019-02-13',
    link:
      'https://www.ibm.com/developerworks/cn/java/j-using-micrometer-to-record-java-metric/index.html',
    description:
      '性能指标（metric）是监控应用运行状态时必不可少的参考数据。Micrometer 可以用统一的 API 来在 Java 应用中记录性能指标，并支持推送数据到不同的监控系统，避免了供应商锁定的问题。本文对 Micrometer 的使用进行详细的介绍，包括与流行监控系统的整合以及与 Spring Boot 的集成。',
  },
  {
    title: '深入理解 Java 函数式编程，第 5 部分: 深入解析 Monad',
    date: '2018-12-03',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-understanding-functional-programming-5/index.html',
    description:
      'Monad 一直是函数式编程领域中充满神秘色彩的概念，也是很难理解的概念。本文从范畴论出发，对 Monad 相关的概念进行介绍，并对几种常见的 Monad 做了详细说明。',
  },
  {
    title: '深入理解 Java 函数式编程，第 4 部分: 使用 Vavr 进行函数式编程',
    date: '2018-12-03',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-understanding-functional-programming-4/index.html',
    description:
      'Java 平台本身所提供的对函数式编程的支持比较有限。Vavr（Javaslang 的后继者）作为 Java 平台上流行的函数式编程库，让函数式编程变得更加简洁易用。本文对 Vavr 中的元组和函数进行了介绍，同时还介绍了 Option、Either、Try 和 Lazy 等值，以及常用的数据结构和模式匹配。',
  },
  {
    title:
      '深入理解 Java 函数式编程，第 3 部分: Java 8 的 Lambda 表达式和流处理',
    date: '2018-12-03',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-understanding-functional-programming-3/index.html',
    description:
      'Java 8 中引入的 Lambda 表达式和流（Stream）为 Java 平台增加了函数式编程的支持。这两个特性深受开发人员的喜爱，可以提高开发效率。本文从 JSR 335 出发对 Lambda 表达式进行了深入的介绍。对于流，则详细介绍了流的源、中间操作和终结操作。',
  },
  {
    title: '深入理解 Java 函数式编程，第 2 部分: 函数式编程中的重要概念',
    date: '2018-12-03',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-understanding-functional-programming-2/index.html',
    description:
      '本文对函数式编程的相关概念通过具体的示例代码进行介绍，内容包括函数式编程的意义、函数类型与高阶函数、部分函数、柯里化、闭包和递归等。',
  },
  {
    title: '深入理解 Java 函数式编程，第 1 部分: 函数式编程思想概论',
    date: '2018-12-02',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-understanding-functional-programming-1/index.html',
    description:
      '函数式编程思想在最近一段时间里得到了流行。不管是主流编程语言提供的内置支持，还是相关第三方库的大量出现，都为函数式编程注入了新的活力。本文是对函数式编程思想的概述，内容包括函数的定义、λ 演算、纯函数、副作用和引用透明性等。',
  },
  {
    title: '使用 Spring REST Docs 创建 REST 服务文档',
    date: '2018-04-17',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-use-spring-rest-docs-to-creat-rest/index.html',
    description:
      'REST 服务是目前最流行的提供开放 API 的方式。对于开放 API 来说，完整准确的文档是必须的。Spring REST Docs 在创建 REST 文档中采用混合的策略，把手写的 Asciidoctor 文档和从单元测试中自动生成的代码片段相结合，可以产生详实而准确的文档。Spring REST Docs 提供了对不同单元测试框架的支持。本文结合实例对 Spring REST Docs 的用法进行详细的说明。',
  },
  {
    title: '使用 Spring Shell 开发 Java 命令行应用',
    date: '2017-11-21',
    link:
      'http://www.ibm.com/developerworks/cn/java/spring-shell-application/index.html',
    description:
      '目前仍然有大量的 Java 应用使用命令行作为交互界面。命令行应用有很多通用的需求，如传递和校验命令行参数和输出帮助等,Spring Shell 为开发命令行应用提供了良好的基础。本文对 Spring Shell 进行详细介绍，包括它提供的参数传递，参数校验，动态命令可用性，内置命令，命令行自定义等功能。本文对 Spring Shell 进行详细介绍，可以作为 Java 命令行程序的首选框架。',
  },
  {
    title: '使用 Spring 5 的 WebFlux 开发反应式 Web 应用',
    date: '2017-10-25',
    link:
      'http://www.ibm.com/developerworks/cn/java/spring5-webflux-reactive/index.html',
    description:
      'Spring 5 中的 WebFlux 模块可以作为开发反应式 Web 应用的基础。借助新增的 WebFlux 模块，开发人员可以使用 WebFlux 创建高性能的 Web 应用和客户端。',
  },
  {
    title: '使用 Reactor 进行反应式编程',
    date: '2017-06-07',
    link:
      'https://www.ibm.com/developerworks/cn/java/j-cn-with-reactor-response-encode/index.html',
    description:
      'Reactor 是一个新的反应式编程库，也是 Spring 5 中反应式 Web 框架的基础。本文对 Reactor 库进行详细的介绍，包括 Flux 和 Mono 序列、常用的操作符、调度器以及测试和调试技巧等。',
  },
  {
    title: 'Nebula： Netflix 开源的 Gradle 插件集合',
    date: '2016-11-15',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-nebula-netflix-gradle/index.html',
    description:
      'Gradle 作为 Java 项目的构建工具，已经得到了广泛的流行，是 Maven 的有力竞争者。Gradle 简洁的脚本语言式的描述方式使得它更加容易被开发人员所使用。在使用 Gradle 构建项目时，通常需要进行一些基本的配置，并整合一些常见的插件。Nebula 是由 Netflix 创建的一个 Gradle 项目的构建框架，其中包含了一些由 Netflix 开发的使用插件，可以作为 Gradle 项目的良好起点。本文对 Nebula 进行了详细的介绍。',
  },
  {
    title: 'JUnit 5 新特性',
    date: '2016-11-09',
    link: 'http://www.ibm.com/developerworks/cn/java/j-junit5/index.html',
    description:
      'JUnit 是 Java 程序单元测试的事实上的标准。JUnit 5 是 JUnit 的最新版本，在保持向后兼容性的同时，又增加了很多新的特性。本文对 JUnit 5 中的新特性进行了详细的介绍，包括新的 JUnit 平台，新的测试用例注解，断言和前置条件，嵌套式测试用例和测试用例的依赖注入等。JUnit 5 还提供了对动态测试用例的支持。还介绍了 JUnit 5 中的扩展机制，以及测试执行生命周期回调方法等高级功能。JUnit 5 可以进一步提升单元测试的编写效率。熟悉 JUnit 5 对开发人员是大有好处的。',
  },
  {
    title: 'Git 分支管理最佳实践',
    date: '2016-06-20',
    link: 'http://www.ibm.com/developerworks/cn/java/j-lo-git-mange/index.html',
    description:
      'Git 是目前最流行的源代码管理工具。熟练使用 Git 已经成为开发人员的必修课之一。对于团队开发来说，如何有效的使用 Git 的分支是一个重要的课题。需要在新功能开发，新版本发布和已有版本的维护等需求中达到一个良好的平衡。另外还需要与持续集成服务有良好的集成。本文对几种主流的 Git 分支管理实践进行了介绍，可以帮助开发团队选择自己最合适的方案。',
  },
  {
    title: 'Ratpack：构建简单高效的 HTTP 微服务',
    date: '2016-03-14',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-ratpack-http-microservice/index.html',
    description:
      '随着微服务架构（microservice）的流行，很多应用被拆分成若干个独立的小应用，并使用 HTTP 协议作为服务直接的通讯协议。每个微服务只完成一个相对独立的功能。对于这些 HTTP 微服务来说，传统的 Servlet 容器过于复杂和厚重，运行所需的内存消耗过高。Ratpack 是一个用来开发高效 HTTP 微服务的 Java 库，它基于 Netty，并采用异步非阻塞和事件驱动的方式来提高系统的性能。Ratpack 可以作为 HTTP 微服务的基础。本文对 Ratpack 进行了详细的介绍。',
  },
  {
    title: 'OkHttp：Java 平台上的新一代 HTTP 客户端',
    date: '2015-12-07',
    link: 'http://www.ibm.com/developerworks/cn/java/j-lo-okhttp/index.html',
    description:
      '在 Java 平台上，我们一般使用 Apache HttpClient 作为通常的 HTTP 客户端。Square 公司开源的 OkHttp 是一个更先进的专注于连接效率的 HTTP 客户端。OkHttp 提供了对 HTTP/2 和 SPDY 的支持，并提供了连接池，GZIP 压缩和 HTTP 响应缓存功能。OkHttp 的 API 接口也更加的简单实用。可以将 OkHttp 作为 Apache HttpClient 的升级与替换，本文将对其进行详细的介绍。',
  },
  {
    title: '使用 Spring HATEOAS 开发 REST 服务',
    date: '2015-01-08',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-SpringHATEOAS/index.html',
    description:
      'REST 架构风格对于大多数开发人员来说都不陌生。在 REST 架构中除了资源和 HTTP 方法之外，超媒体控制也是重要的一环。HATEOAS (Hypermedia as the Engine of Application State) 是 REST 架构风格上的一种限制。基于 HATEOAS 的 REST 服务可以允许服务端和客户端在最大程度上的解耦。客户端和服务端并不需要严格的接口规范。客户端可以通过服务端提供的超媒体表示来动态与服务端进行交互。Spring HATEOAS 与 Spring 框架中已有的 Web 服务功能很好的整合在一起，可以创建满足 HATEOAS 要求的 REST 服务。在开发 REST 服务时，推荐使用 Spring HATEOAS。本文对 Spring HATEOAS 进行了详细的介绍。',
  },
  {
    title: '使用 Spring Boot 快速构建 Spring 框架应用',
    date: '2014-09-01',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-spring-boot/index.html',
    description:
      'Spring 框架作为目前非常流行的一个 Java 应用开发框架，它所包含的内容是非常繁多的。Spring 框架包含几十个不同的子项目，涵盖应用开发的不同方面。要在这些子项目之间进行选择，并快速搭建一个可以运行的应用是比较困难的事情。Spring Boot 的目的在于快速创建可以独立运行的 Spring 应用。通过 Spring Boot 可以根据相应的模板快速创建应用并运行。Spring Boot 可以自动配置 Spring 的各种组件，并不依赖代码生成和 XML 配置文件。Spring Boot 可以大大提升使用 Spring 框架时的开发效率。',
  },
  {
    title: '使用 Spring Social 连接社交网络',
    date: '2014-07-27',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-spring-social/index.html',
    description:
      '随着社交网络的流行，在应用中加入对主流社交网络的支持变成了一个重要的功能。比如允许用户使用第三方社交网络的账号，如 Twitter、Facebook、LinkedIn 或新浪微博的账号，直接登录；或是读取用户在其他社交网站上的信息等。很多社交网站都提供开放 API 允许开发人员使用，不过需要开发人员了解 OAuth 等其他实现细节。Spring Social 是 Spring 框架中的一个子项目，该项目提供了一个可扩展的框架，允许开发人员很方便地与其他社交网络平台进行集成，同时也提供了流行社交平台的相关 API 支持。本文会详细介绍 Spring Social 项目以及如何在项目中使用它。对于需要在应用中集成社交网站支持的开发人员来说，Spring Social 提供的功能是非常方便的。',
  },
  {
    title: 'Java 日志管理最佳实践',
    date: '2013-11-08',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-practicelog/index.html',
    description:
      '日志记录是应用程序运行中必不可少的一部分。具有良好格式和完备信息的日志记录可以在程序出现问题时帮助开发人员迅速地定位错误的根源。对于开发人员来说，在程序中使用日志API记录日志并不复杂，不过遵循一些最佳实践可以更好的利用日志。本文介绍了在Java程序中记录日志的最佳实践，同时也介绍了如何使用开源软件对日志进行聚合和分析。',
  },
  {
    title: '图形数据库 Neo4j 开发实战',
    date: '2013-06-20',
    link: 'http://www.ibm.com/developerworks/cn/java/j-lo-neo4j/index.html',
    description:
      'Neo4j 是一个高性能的 NoSQL 图形数据库。Neo4j 使用图（graph）相关的概念来描述数据模型，把数据保存为图中的节点以及节点之间的关系。很多应用中数据之间的关系，可以很直接地使用图中节点和关系的概念来建模。对于这样的应用，使用 Neo4j 来存储数据会非常的自然，要优于使用关系数据库。本文对 Neo4j 进行了深入的介绍，并结合具体的实例来进行详细的说明，可以让您对 Neo4j 有深入的了解，从而可以在应用开发中恰当地选择 Neo4j 来作为存储方式。',
  },
  {
    title: '详解 ECMAScript 6 中的生成器（Generator）',
    date: '2018-09-04',
    link:
      'http://www.ibm.com/developerworks/cn/web/wa-es6-generator/index.html',
    description:
      '生成器（Generator）是 ECMAScript 6 规范中的一个重要组成部分，在很多场景中有着重要的应用。如果使用得当，生成器可以优雅地解决很多常见的问题。不过生成器的使用并不是特别容易掌握。本文对生成器做了详细的介绍，从基本用法到高级用法，再到在实际开发中的应用，可以帮助开发人员充分掌握生成器。',
  },
  {
    title: '使用 bluebird 实现更强大的 Promise',
    date: '2018-01-22',
    link:
      'http://www.ibm.com/developerworks/cn/web/wa-lo-use-bluebird-implements-power-promise/index.html',
    description:
      'Promise 是 JavaScript 里面一个非常重要的对象，它表示一个将来可用的值。通常用来表示异步操作的结果。Promise 的使用者可以根据 Promise 的结果来进行对应的操作。不过 JavaScript 自身的 Promise 的功能有限。bluebird 在基本的 Promise 的功能之上，增加了更多实用功能，可以作为 JavaScript 应用的首选实现。本文对 bluebird 进行详细的介绍。',
  },
  {
    title: '使用 PostCSS 进行 CSS 处理',
    date: '2016-04-26',
    link:
      'http://www.ibm.com/developerworks/cn/web/1604-postcss-css/index.html',
    description:
      '在 Web 应用开发中，CSS 的处理一直是一个重要的环节。由于 CSS 规范本身的发展滞后，Web 开发人员大多使用 Sass 或 Less 这样的 CSS 预处理器语言来编写样式，再转换成 CSS 代码。PostCSS 是目前流行的一个工具，它使用 JavaScript 代码来转换 CSS 中的样式。PostCSS 通过不同的插件来支持对 CSS 的不同处理，目前已有 200 多个不同的插件。PostCSS 的插件可以完成各种不同的功能，如支持变量和混入，支持未来的 CSS 语法和添加浏览器特定前缀等。本文将对 PostCSS 及其常用插件进行详细的介绍。',
  },
  {
    title: '从 BEM 谈大型项目中 CSS 的组织和管理',
    date: '2015-12-07',
    link:
      'http://www.ibm.com/developerworks/cn/web/1512_chengfu_bem/index.html',
    description:
      '随着 Web 应用开发的不断复杂化，大型 Web 应用的 CSS 文件数量较多而且难以组织。本文从 BEM 这一 CSS 命名方法学出发，介绍在大型前端项目中对于 CSS 的组织方式，探索相关的最佳实践。\n',
  },
  {
    title: '通过 Browserify 在浏览器中使用 NodeJS 模块\n',
    date: '2015-01-22',
    link:
      'http://www.ibm.com/developerworks/cn/web/1501_chengfu_browserify/index.html',
    description:
      'NodeJS 的开发人员已经习惯于使用 require 方法来加载其他模块。这种模块化的机制也为开发人员所熟悉。相对来说，在 Web 开发端的 JavaScript 的模块化管理则比较复杂，存在多种不同的规范和实践。Browerify 把 NodeJS 对模块的管理机制引入到了浏览器端，允许使用同样的方式来加载模块。如果应用的后台是基于 NodeJS 的，在前端采用 Browerify 可以统一模块的管理。即便后台不是基于 NodeJS，也可以复用已有的高质量的 NodeJS 模块。本文详细介绍 Browserify 的使用。\n',
  },
  {
    title: '深入理解 CSS3 弹性盒布局模型',
    date: '2014-09-04',
    link:
      'http://www.ibm.com/developerworks/cn/web/1409_chengfu_css3flexbox/index.html',
    description:
      '弹性盒布局模型（Flexible Box Layout）是 CSS3 规范中提出的一种新的布局方式。该布局模型的目的是提供一种更加高效的方式来对容器中的条目进行布局、对齐和分配空间。这种布局方式在条目尺寸未知或动态时也能工作。这种布局方式已经被主流浏览器所支持，可以在 Web 应用开发中使用。本文详细的介绍该布局模型以及如何在具体开发中应用该布局模型来简化常见的页面布局场景。',
  },
  {
    title: 'Yeoman：Web 应用开发流程与工具',
    date: '2014-02-17',
    link:
      'http://www.ibm.com/developerworks/cn/web/1402_chengfu_yeoman/index.html',
    description:
      '随着 Web 2.0 和 HTML 5 的流行，现在的 Web 应用所能提供的功能和交互能力比之前传统的 Web 应用要强大很多。应用的很多实现逻辑被转移到了浏览器端来实现。浏览器不再只提供单一的数据接收和展现功能，而是提供更多的用户交互能力。浏览器端所包含的 HTML、CSS 和 JavaScript 代码也变得更加复杂。对于日益复杂的前端代码，需要有更好的流程和工具来管理开发的各个方面，包括初始的代码结构、开发流程和自动化测试等。Yeoman 是一个新兴的工具。它结合了 Yo、Grunt 和 Bower 等工具，组成了一个完整的工具集合，提供各种 Web 应用开发中所需的实用功能。',
  },
  {
    title: 'HTML5 服务器推送事件（Server-sent Events）实战开发',
    date: '2013-07-18',
    link:
      'http://www.ibm.com/developerworks/cn/web/1307_chengfu_serversentevent/index.html',
    description:
      '服务器推送事件（Server-sent Events）是 HTML 5 规范中的一个组成部分，可以用来从服务端实时推送数据到浏览器端。相对于与之类似的 COMET 和 WebSocket 技术来说，服务器推送事件的使用更简单，对服务器端的改动也比较小。对于某些类型的应用来说，服务器推送事件是最佳的选择。本文对服务器推送技术进行了详细的介绍，包含浏览器端和服务器端的相应实现细节，为在实践中使用该技术提供了指南。',
  },
  {
    title: '深入探讨 ECMAScript 规范第五版',
    date: '2013-05-23',
    link:
      'http://www.ibm.com/developerworks/cn/web/1305_chengfu_ecmascript5/index.html',
    description:
      '随着 Web 应用开发的流行，JavaScript 越来越受到开发人员的重视。作为 ECMAScript 的变体，JavaScript 语言的很多语法特性都由 ECMAScript 规范来定义。ECMAScript 规范是学习掌握 JavaScript 的最好的第一手资料。很多在使用 JavaScript 时会遇到的常见问题，都可以在 ECMAScript 规范中找到答案。本文对 2009 年 12 月发布的 ECMAScript 规范第五版中的重点内容进行了详细介绍，尤其是相对于 ECMAScript 规范第三版所新增的内容。',
  },
  {
    title: 'Firefox 扩展开发进阶指南',
    date: '2011-08-25',
    link:
      'http://www.ibm.com/developerworks/cn/web/1108_chengfu_ffplugin/index.html',
    description:
      'Firefox 扩展可以从不同的方面增强 Firefox 浏览器的功能，方便用户使用。本文在《实战 Firefox 扩展开发》一文的基础上，重点介绍了 Firefox 扩展开发中的一些高级话题，包括高级用户界面元素及其操作、XBL、XUL 数据模板、JavaScript 代码模块、XPCOM、国际化和扩展自动更新等。本文可以帮助开发人员更好的理解这些高级特性，从而开发出功能更加强大的 Firefox 扩展。',
  },
  {
    title:
      '使用 Dojo 的 Ajax 应用开发进阶教程，第 10 部分: Ajax 应用的测试、安全、性能及其它',
    date: '2010-12-27',
    link:
      'http://www.ibm.com/developerworks/cn/web/1012_chengfu_doh/index.html',
    description:
      '在 Ajax 应用开发中，应用自身的功能性需求需要满足，而一些非功能性需求却常常得不到足够的重视。而实际上这些非功能性需求对一个项目的成功来说是非常重要的，应该在项目开发的最初就考虑起来。本文针对 Ajax 应用相关的常见非功能性需求做了详细的说明，包括如何使用 D.O.H 进行单元测试、性能测试和界面相关的测试，分析了常见的 Ajax 应用的安全漏洞，讨论了 Ajax 应用的构建过程应该包括的重要步骤，最后讨论了如何提高 Ajax 应用的性能。',
  },
  {
    title:
      '使用 Dojo 的 Ajax 应用开发进阶教程，第 9 部分: Dojo 扩展库：丰富的组件资源仓库',
    date: '2010-12-22',
    link:
      'http://www.ibm.com/developerworks/cn/web/1012_chengfu_dojox/index.html',
    description:
      '大量易于定制的组件对于 Ajax 应用开发来说是非常重要的，可以极大的提高开发效率和提高代码质量。Dojo 扩展库（dojox）在 Dojo 基本库、核心库和 Dijit 库的基础上，提高了丰富的组件。利用这些组件，可以满足 Ajax 应用开发中的一些常见需求。本文重点介绍了 Dojo 扩展库中的常用组件的使用，可以帮助开发人员了解和利用这些组件。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 5 部分: 浏览器中的事件处理',
    date: '2010-09-20',
    link:
      'http://www.ibm.com/developerworks/cn/web/1009_chengfu_browserevent/index.html',
    description:
      '事件处理是 Ajax 应用中的重要组成部分，也是应用动态变化的源动力。本文详细介绍了浏览器中的事件处理相关的内容，包括注册事件监听器、事件发生之后的传播机制、编写事件监听器等，还介绍了 Dojo 对事件处理提供的支持。最后介绍了与浏览器内存泄露和性能相关的最佳实践。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 6 部分: Dojo 基本库深入介绍',
    date: '2010-09-13',
    link:
      'http://www.ibm.com/developerworks/cn/web/1009_chengfu_basiccore/index.html',
    description:
      'Dojo 基本库是 Dojo 框架的基础。它包含了在 Ajax 应用开发中最经常使用的核心功能。深入了解 Dojo 基本库是理解 Dojo 核心库、用户界面库和扩展库的基础。本文详细介绍了 Dojo 基本库中所包含的重要内容，其中包括辅助工具方法、dojo.Deferred、主题发布与订阅、XMLHTTPRequest、模块化机制、动画效果、配置与作用上下文和面向对象 JavaScript 等。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 4 部分: DOM 查询与操作',
    date: '2010-09-09',
    link:
      'http://www.ibm.com/developerworks/cn/web/1009_chengfu_dom/index.html',
    description:
      'DOM 查询和操作是 Ajax 应用中的常见用法，是 Ajax 应用中页面动态和局部刷新的实现基础。本文详细介绍了 DOM 的基本概念，以及如何使用 DOM 基本 API 和 Dojo 来对页面文档树进行查询和操作，重点介绍了 dojo.query 和 dojo.NodeList。最后介绍了 DOM 操作性能、dojo.NodeList 插件和 dojo.behavior 等高级话题。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 3 部分: 深入理解 CSS',
    date: '2010-09-02',
    link:
      'http://www.ibm.com/developerworks/cn/web/1009_chengfu_dojocss/index.html',
    description:
      'CSS 作为 HTML 页面中控制展现的标准技术，已经为广大 Web 开发人员所熟悉。虽然 CSS 本身的语法比较简单，但是在复杂的 Web 应用中编写和管理 CSS 并非一件容易的事情。本文讨论了 CSS 规范中比较复杂和容易出错的部分，接着讨论了浏览器的兼容性和如何开发出可维护的 CSS，最后介绍了相关的框架和工具以及 Dojo 提供的 API 支持。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 2 部分: 富含语义的 HTML',
    date: '2009-07-16',
    link:
      'http://www.ibm.com/developerworks/cn/web/0907_chengfu_html/index.html',
    description:
      'HTML 语言是互联网的基础。如何正确合理的编写 HTML 文档，是很多 Web 开发人员关心的问题。富含语义的 HTML 是一种 Web 应用开发的实践，它强调从文档所需要表达的语义出发，使用 HTML 语法中表示文档结构和富含语义的元素来编写 HTML 文档，从而使得 Web 应用的结构与展示分离，降低各部分之间的耦合度。随着 Ajax 应用的流行，这种实践越来越为 Web 开发人员所接受。本文详细介绍富含语义的 HTML 这一开发实践，供 Web 开发人员参考。  ',
  },
  {
    title:
      '使用 Dojo 的 Ajax 应用开发进阶教程，第 1 部分: JavaScript 技巧与高级特性',
    date: '2008-11-14',
    link: 'http://www.ibm.com/developerworks/cn/web/wa-lo-dojoajax1/index.html',
    description:
      '随着 Ajax 应用的流行，JavaScript 语言得到了越来越多的关注。开发人员对 JavaScript 的使用也日益深入。 JavaScript 已经成为构建 Ajax 应用的重要基石。 JavaScript 作为一种专门设计在浏览器中执行的动态语言，有许多重要的特性。熟悉这些特性可以帮助更好的开发 Ajax 应用。本文章介绍了 JavaScript 语言中十三个比较重要的特性，包括 prototype、执行上下文、作用域链和闭包等。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 7 部分: Dojo 核心库深入介绍',
    date: '2010-09-20',
    link:
      'https://www.ibm.com/developerworks/cn/web/1009_chengfu_coreintro/index.html',
    description:
      'Dojo 核心库构建于 Dojo 基本库之上，为 Ajax 应用的开发提供了更加丰富的功能。掌握 Dojo 核心库中包含的内容，可以开发人员减少代码量，而把工作精力集中在与业务逻辑相关的组件的实现上。',
  },
  {
    title: '使用 Dojo 的 Ajax 应用开发进阶教程，第 8 部分: Dijit 开发最佳实践',
    date: '2010-09-26',
    link:
      'https://www.ibm.com/developerworks/cn/web/1009_chengfu_dijit/index.html',
    description:
      'Dijit 组件（widget）是 Dojo 提供的图形用户界面组件库。它提供了 Ajax 应用开发中会用到的常用组件，可以帮助开发人员快速的构建 Ajax 应用。本文并不会介绍 Dojo 默认提供的组件，而是侧重于介绍 Dijit 组件的编程模型和最佳实践，其目的是帮助开发人员更好的开发自己的 Dijit 组件。',
  },
  {
    title: '实战 Firefox 扩展开发',
    date: '2008-02-28',
    link:
      'http://www.ibm.com/developerworks/cn/web/wa-lo-firefox-ext/index.html',
    description:
      'Firefox 浏览器自身提供良好的扩展结构，使得开发人员可以方便的扩展其行为。很多网站，比如 del.icio.us，都提供 Firefox 扩展来提供更好的用户体验。学习这方面的知识不仅对于网站开发人员是有用的，其他人也可以通过开发扩展来解决一些使用 Firefox 中遇到的具体问题。本文以一个能够批量下载某个 HTML 页面上所有图片的 Firefox 扩展作为案例，详细的介绍了 Firefox 扩展的开发流程。这其中包括构建开发环境，使用 XUL 来描述用户界面，使用 JavaScript 来为扩展增加行为，扩展的打包、发布和更新等方面的内容。\n',
  },
  {
    title: '面向文档的数据库 CouchDB',
    date: '2009-08-26',
    link:
      'http://www.ibm.com/developerworks/cn/opensource/os-cn-couchdb/index.html',
    description:
      'Apache CouchDB 是一个面向文档的数据库管理系统。它提供以 JSON 作为数据格式的 REST 接口来对其进行操作，并可以通过视图来操纵文档的组织和呈现。 CouchDB 是 Apache 基金会的顶级开源项目。本文将介绍 CouchDB 的基本概念，包括文档、视图，REST API，并通过一个实际的图书点评网站来说明如何用 CouchDB 开发 Web 应用。',
  },
  {
    title: '使用 node.js 进行服务器端 JavaScript 编程',
    date: '2011-07-21',
    link:
      'http://www.ibm.com/developerworks/cn/web/1107_chengfu_nodejs/index.html',
    description:
      'node.js 是一个可以使用 JavaScript 开发服务器端应用的平台。它依托于 Google V8 JavaScript 引擎，并采用事件 I/O 的架构，可以用来创建高性能服务器。本文详细介绍了 node.js 的基本知识、模块化的结构、事件驱动的机制以及常用的模块。',
  },
  {
    title: '使用 restify 开发 REST API',
    date: '2017-12-05',
    link:
      'http://www.ibm.com/developerworks/cn/web/wa-lo-use-restify-develop-rest-api/index.html',
    description:
      '本文对 restify 进行具体的介绍，包括 HTTP 响应处理、HTTP 请求路由、多版本、WebSocket、内容协商、错误处理和插件等。通过阅读本文，读者可以掌握如何用 restify 高效开发基于 Node.js 的 REST API。',
  },
  {
    title: 'Java 9 新特性概述',
    date: '2017-12-28',
    link:
      'http://www.ibm.com/developerworks/cn/java/the-new-features-of-Java-9/index.html',
    description:
      'Java 9 已经于 2017 年 9 月 21 号正式发布。作为 Java 8 发布 3 年半之后的新版本，Java 社区对 Java 9 有很多的期待。Java 9 中最重要的新特性是引入了模块系统，使得可以开发模块化项目。除此之外，还有很多新特性，包括语言的改动、jshell、平台日志 API、变量句柄等，也对已有的 API 和 JVM 做了一些修改。本文对 Java 9 中的新特性做了概括性的介绍。',
  },
  {
    title:
      '使用 Spring、Elasticsearch 及 Logstash 构建企业级数据搜索和分析平台',
    date: '2015-10-12',
    link:
      'http://www.ibm.com/developerworks/cn/java/j-lo-Spring-Elasticsearch-Logstash/index.html',
    description:
      '在企业应用中，数据的搜索和分析永远是一个重要的话题。Elasticsearch 作为 Java 平台中搜索领域的佼佼者，提供了强大的搜索功能。Logstash 作为数据搜索工具，也已经整合到 Elasticsearch 产品线之中。Spring 中的 Spring Data 子项目提供了对于 Elasticsearch 的集成。本文详细介绍如何整合 Spring、Elasticsearch 和 Logstash 来构建完整的企业级数据搜索和分析平台。',
  },
]

export default technicalArticles
